import { AutoPlayCarousel } from '../../../shadcn-ui/components/ui/auto-play-carousel';

type CustomerLogo = {
  src: string;
  alt: string;
  height?: number;
  width?: number;
};

const adstartCustomersLogos: CustomerLogo[] = [
  { src: '/agencies-logos/fg.png', alt: 'FG logo' },
  { src: '/agencies-logos/eplus.png', alt: 'Agência E-plus Logo' },
  { src: '/agencies-logos/commerce-growth.png', alt: 'CommerceGrowth logo' },
  { src: '/agencies-logos/ciclo-logo.png', alt: 'Ciclo logo' },
  { src: '/agencies-logos/etraction.png', alt: 'Etraction logo' },
  { src: '/agencies-logos/genesis.png', alt: 'Genesis ads Logo' },
  {
    src: '/agencies-logos/avantage-midia.png',
    alt: 'Avantage Midia Logo',
  },
  { src: '/agencies-logos/qg.png', alt: 'Qg digital logo' },
  { src: '/agencies-logos/wave.png', alt: 'Wave logo' },
  { src: '/agencies-logos/econverse.png', alt: 'EConverse logo' },
  { src: '/agencies-logos/rg.png', alt: 'Rg logo' },
  {
    src: '/agencies-logos/way-ecommerce.png',
    alt: 'Way logo',
    height: 64,
    width: 100,
  },
];

const zenderCustomersLogos: CustomerLogo[] = [
  { src: '/customers/anime-logo.svg', alt: 'Anime logo' },
  { src: '/customers/arm-fitness-logo.svg', alt: 'ARM Fitness logo' },
  { src: '/customers/authoria-logo.svg', alt: 'Authoria logo' },
  { src: '/customers/luzzoo-logo.svg', alt: 'Luzzoo logo' },
  { src: '/customers/momi-logo.svg', alt: 'Momi logo' },
  { src: '/customers/youccie-logo.svg', alt: 'Youccie logo' },
  { src: '/customers/yeesco.png', alt: 'Yeesco logo' },
  { src: '/customers/dolceme-logo.svg', alt: 'Dolceme logo' },
];

const firstRow = adstartCustomersLogos.slice(0, adstartCustomersLogos.length);
const secondRow = zenderCustomersLogos.slice(0, zenderCustomersLogos.length);

export function CustomersLogos({ isAdstart }: { isAdstart: boolean }) {
  return (
    <div className="flex w-full flex-col items-center justify-center mb-8 md:mb-12">
      <AutoPlayCarousel
        className={`${isAdstart ? '' : 'hidden'} [--duration:30s]`}
      >
        {firstRow.map(({ src, alt, height = 68, width = 180 }, index) => (
          <div key={index} className="flex flex-row items-center mx-12">
            <img width={width} height={height} alt={alt} src={src} />
          </div>
        ))}
      </AutoPlayCarousel>
      <AutoPlayCarousel
        className={`${!isAdstart ? '' : 'hidden'} [--duration:22s]`}
      >
        {secondRow.map(({ src, alt, height = 68, width = 180 }, index) => (
          <div key={index} className="flex flex-row items-center mx-12">
            <img width={width} height={height} alt={alt} src={src} />
          </div>
        ))}
      </AutoPlayCarousel>
    </div>
  );
}
